<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <spinner v-if="isLoading" />
    <div class="py-3" />

    <base-material-card
      color="primary"
      dark
      icon="mdi-webhook"
      title="Payment Webhooks"
      class="px-5 py-3"
    >
      <template>
        <div
          class="d-flex"
        >
          <v-col class="col-lg-4 col-md-6 col-sm-10">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              autocomplete="off"
              maxlength="64"
              clearable
            />
          </v-col>
          <div class="ml-auto">
            <v-col>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    large
                    icon
                    color="blue"
                    v-on="on"
                    @click="refresh"
                  >
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>Refresh</span>
              </v-tooltip>
            </v-col>
          </div>
        </div>
        <v-data-table
          :headers="headers"
          :items="webhooks"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          class="elevation-1"
          :loading="isLoading"
          :search="search"
          loading-text="Loading webhooks"
          multi-sort
          show-expand
          :page-count="pageCount"
        >
          <template v-slot:[`item.success`]="{ item }">
            <v-chip
              :color="getColor(item.success)"
              dark
            >
              {{ item.success }}
            </v-chip>
          </template>
          <template v-slot:[`item.corrupted`]="{ item }">
            <v-chip
              :color="getColor(!item.corrupted)"
              dark
            >
              {{ item.corrupted }}
            </v-chip>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            <v-chip
              color=""
              dark
            >
              {{ formatDate(item.createdAt) }}
            </v-chip>
          </template>
          <template v-slot:expanded-item="{ item }">
            <td :colspan="headers.length">
              <pre>{{ item.data }}</pre>
            </td>
          </template>
        </v-data-table>
        <template v-if="!isLoading">
          <div class="text-center">
            <v-row>
              <v-col
                cols="12"
                md="7"
              >
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  :disabled="webhooks.length === 0"
                  :total-visible="6"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-card-text
                  v-if="total > 0"
                  class="subtitle-1"
                >
                  {{ `${page === 1 ? 1 : (page-1) * itemsPerPage + 1} - ${(page * itemsPerPage) > total ? total:(page * itemsPerPage)} of ${total}` }}
                </v-card-text>
                <v-card-text
                  v-else
                  class="subtitle-1"
                >
                  0 of 0
                </v-card-text>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-select
                  v-model="itemsPerPage"
                  type="number"
                  :disabled="webhooks.length === 0"
                  :items="perPageOptions"
                  label="Total visible"
                  @change="page = 1"
                />
              </v-col>
            </v-row>
          </div>
        </template>
      </template>
    </base-material-card>
  </v-container>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import moment from 'moment'
  import spinner from '@/components/layouts/Spinner'
  import { redirectMixin } from '@/mixins/redirectMixin'

  export default {
    components: {
      spinner,
    },
    mixins: [redirectMixin],
    data () {
      return {
        page: 1,
        pageCount: 1,
        itemsPerPage: 30,
        total: 0,
        perPageOptions: [30, 60, 120, 240],
        expanded: [],
        singleExpand: false,
        headers: [
          {
            text: 'Success',
            align: 'start',
            sortable: true,
            filterable: false,
            value: 'success',
          },
          { text: 'Corrupted', value: 'corrupted', sortable: true, filterable: false },
          { text: 'Status', value: 'status', sortable: true },
          { text: 'Created', value: 'createdAt', sortable: true },
        ],
        search: '',
      }
    },
    computed: {
      ...mapState({
        webhooks: state => state.webhooksData.webhooks,
        metadata: state => state.webhooksData.metadata,
        isLoading: state => state.webhooksData.isLoading,
        error: state => state.webhooksData.error,
        currentUser: state => state.userData.currentUser,
      }),
    },
    watch: {
      metadata: function () {
        if (this.metadata && this.metadata.pageCount) {
          this.pageCount = this.metadata.pageCount
          this.total = this.metadata.total
        }
      },
      page: function () {
        this.refresh()
      },
      itemsPerPage: function () {
        this.refresh()
      },
      error: function () {
        if (this.error && this.error.statusCode === 401) {
          const { email } = this.currentUser

          this.redirectToSignin(email, 'Kindly log in again, your session may have expired or you may have logged out from another device')
        }
      },
    },
    created () {
      this.refresh()
    },
    methods: {
      ...mapActions('webhooksData', ['getWebhooks']),
      formatDate (date) {
        return moment(date).format('MMMM Do, YYYY - hh:mm a')
      },
      getColor (success) {
        if (success) return 'green'
        else return 'red'
      },
      refresh () {
        const { token } = this.currentUser
        this.getWebhooks({ token, page: this.page, perPage: this.itemsPerPage })
      },
    },
  }
</script>
